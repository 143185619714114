import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getUrl } from 'components/caller';
import { HandleErrorResponse, HandleSuccessResponse } from 'components/util';
import { useNavigate } from 'react-router-dom';

function withDataFetching(WrappedComponent) {
  return function WithDataFetching(props) {
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Log out the user, clear session, and reload the page once
    const processLogout = () => {
      localStorage.clear();
      sessionStorage.setItem('reloadAfterLogout', 'true'); // Set a flag in sessionStorage
      window.location.reload();  // Reload the page
    };

    useEffect(() => {
      // If the page has already been reloaded after a logout, we don't want to reload again.
      if (sessionStorage.getItem('reloadAfterLogout')) {
        sessionStorage.removeItem('reloadAfterLogout'); // Remove the flag after reload
        return;
      }

      const getMyProfile = async () => {
        const processUrl = await getUrl("/user-profile/me");
        const auth = localStorage.getItem("auth");

        if (!auth) {
          // If there's no auth token, log out the user
          processLogout();
          return;
        }

        try {
          const response = await axios.get(processUrl, {
            headers: {
              authorization: auth,
            },
          });
          if (HandleSuccessResponse(response?.status)) {
            setUserProfile(response?.data?.data);
          }
        } catch (error) {
          const errorCap = await HandleErrorResponse(error);
          setError(errorCap);
          
          // Clear session and reload only once if an error occurs
          processLogout();
        } finally {
          setLoading(false);
        }
      };

      getMyProfile();
    }, [navigate]); // Only trigger on component mount

    // Pass loading, userProfile, and error to the wrapped component
    return <WrappedComponent {...props} userProfile={userProfile} loading={loading} error={error} />;
  };
}

export default withDataFetching;
