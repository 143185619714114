import withDataFetching from 'components/hoc'
import React from 'react'
import { Outlet } from 'react-router-dom'

function WithAuth({children}:{children:React.ReactNode}) {
  return (
    <>
       <Outlet />
    </>
  )
}


export default withDataFetching(WithAuth)