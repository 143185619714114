import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "pages/Home";
import NotFound from "pages/NotFound";
// const {SuccessTransaction} = React.lazy(
//   () => import("pages/sucessTransaction"),
// );
import ScrollToTop from "./components/ScrollTo";

import { Img } from "components";
import WithAuth from "pages/layout/WithAuth";
const UserBeneficiary = React.lazy(() => import("pages/UserBeneficiary"));
const ReferAndEarn = React.lazy(() => import("pages/ReferAndEarn"));
const Offers = React.lazy(() => import("pages/Offers"));
const TraansactioninformationThirteen = React.lazy(
  () => import("pages/TraansactioninformationThirteen")
);
const Notifications = React.lazy(
  () => import("pages/Notification")
);
const Favorite = React.lazy(() => import("pages/Favorite"));
const Schedule = React.lazy(() => import("pages/Scheduling"));
const Traansactioninformation = React.lazy(
  () => import("pages/UserBeneficiary")
);
const TransactionHistory = React.lazy(() => import("pages/TransactionHistory"));

const ConfirmPayment = React.lazy(() => import("pages/payment/confirm"));
const EmailVerification = React.lazy(
  () => import("pages/Settings/email-verification")
);
const Settings = React.lazy(() => import("pages/Settings"));
const TraansactioninformationFourteen = React.lazy(
  () => import("pages/TraansactioninformationFourteen")
);
const TraansactioninformationOne = React.lazy(
  () => import("pages/TraansactioninformationOne")
);
const Transfer = React.lazy(() => import("pages/Transfer"));
const FundWallet = React.lazy(() => import("pages/FundWallet"));
const TraansactioninformationTwelve = React.lazy(
  () => import("pages/TraansactioninformationTwelve")
);
const Profile = React.lazy(() => import("pages/Profile"));
const TraansactioninformationTwo = React.lazy(
  () => import("pages/TraansactioninformationTwo")
);
const TraansactioninformationNine = React.lazy(
  () => import("pages/TraansactioninformationNine")
);
const TermsAndCondition = React.lazy(
  () => import("pages/TermsCondition")
);
const PrivacyPolicy = React.lazy(
  () => import("pages/PrivacyPolicy")
);
const DeleteUser = React.lazy(
  () => import("pages/deleteUser")
);
const CouponView = React.lazy(() => import("pages/CouponView"));
const InputInvoiceDetails = React.lazy(
  () => import("pages/InputInvoiceDetails")
);
const PaymentoptionsOne = React.lazy(() => import("pages/PaymentoptionsOne"));
const Signup = React.lazy(() => import("pages/Signup"));
const Login = React.lazy(() => import("pages/Login"));
const About = React.lazy(() => import("pages/About"));
const ContactUs = React.lazy(() => import("pages/Contact"));
const ForgotPassword = React.lazy(() => import("pages/ForgetPassword"));
const Paymenttoptions = React.lazy(() => import("pages/Paymenttoptions"));
const Paymentoptions = React.lazy(() => import("pages/Paymentoptions"));
const CouponViewOne = React.lazy(() => import("pages/CouponViewOne"));
const InputInvoiceDetailsOne = React.lazy(
  () => import("pages/InputInvoiceDetailsOne")
);
const OperatorView = React.lazy(() => import("pages/OperatorView"));
const Homepage = React.lazy(() => import("pages/Homepage"));
const ProjectRoutes = () => {
  return (
    <React.Suspense
      fallback={
        <div className="w-full h-screen min-h-full flex items-center justify-center">
          <Img
            className="md:w-[110px]"
            src="images/loader.gif"
            alt="search_Two"
          />
        </div>
      }
    >
      <Router>
        <ScrollToTop />
        <Routes>
        <Route path="/login" element={<Login />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
        <Route element={<WithAuth />}>
          <Route path="/" element={<Homepage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/operatorView" element={<OperatorView />} />
          <Route
            path="/inputinvoicedetailsone"
            element={<InputInvoiceDetailsOne />}
          />
          <Route path="/couponviewone" element={<CouponViewOne />} />
          <Route path="/paymentoptions" element={<Paymentoptions />} />
          <Route path="/paymenttoptions" element={<Paymenttoptions />} />
         
          <Route path="/paymentoptionsone" element={<PaymentoptionsOne />} />
          {/* <Route
            path="/SuccessTransaction"
            element={<SuccessTransaction />}
          /> */}
          <Route
            path="/inputinvoicedetails"
            element={<InputInvoiceDetails />}
          />
          <Route path="/couponview" element={<CouponView />} />
          <Route
            path="/traansactioninformationnine"
            element={<TraansactioninformationNine />}
          />
          <Route
            path="/traansactioninformationtwo"
            element={<TraansactioninformationTwo />}
          />
          <Route path="/Profile" element={<Profile />} />
          <Route
            path="/notifications"
            element={<Notifications />}
          />
          <Route
            path="/traansactioninformationtwelve"
            element={<TraansactioninformationTwelve />}
          />
          <Route path="/FundWallet" element={<FundWallet />} />
          <Route path="/payment/confirm" element={<ConfirmPayment />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/Transfer" element={<Transfer />} />
          <Route
            path="/traansactioninformationone"
            element={<TraansactioninformationOne />}
          />
          <Route path="/Settings" element={<Settings />} />
          <Route
            path="/traansactioninformationfourteen"
            element={<TraansactioninformationFourteen />}
          />
          <Route path="/TransactionHistory" element={<TransactionHistory />} />
          <Route
            path="/traansactioninformation"
            element={<Traansactioninformation />}
          />
          <Route path="/Favorite" element={<Favorite />} />
          <Route path="/Schedules" element={<Schedule />} />
         
          <Route
            path="/traansactioninformationthirteen"
            element={<TraansactioninformationThirteen />}
          />
          <Route path="/Offers" element={<Offers />} />
          <Route path="/ReferAndEarn" element={<ReferAndEarn />} />
          <Route path="/UserBeneficiary" element={<UserBeneficiary />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/delete-user" element={<DeleteUser />} />
          </Route>
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
