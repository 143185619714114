/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import axios from "axios";
// import { HandleErrorResponse, HandleSuccessResponse } from "../util";

// export const axiosGet = async (endPoint) => {
//   const baseUrl = `${process.env.REACT_APP_BASE_URL}${endPoint}`;
//   let returnResponse = null;
//   try {
//     const response = await axios.get(baseUrl);
//     if (HandleSuccessResponse(response?.status)) {
//       returnResponse = response?.data?.data;
//     }
//   } catch (error) {
//     const errorCap = HandleErrorResponse(error);
//     returnResponse = errorCap;
//   }
//   return returnResponse;
// };


// export const axiosPost = async (endPoint, payLoader) => {
//   const baseUrl = `${process.env.REACT_APP_BASE_URL}${endPoint}`;
//   let returnResponse = null;
//   try {
//     const response = await axios.post(
//       baseUrl,
//       payLoader
//     );
//     if (HandleSuccessResponse(response?.status)) {
//       returnResponse = response?.data?.data;
//     }
//     return returnResponse;
//   } catch (error) {    
//     const errorCap = HandleErrorResponse(error);
//     console.log(errorCap, "errorCap");
//   }
// };
export const getUrl = async (endPoint:string) => {
  // console.log(process.env.REACT_APP_BASE_URL,'endPoint')
  const baseUrl = `${process.env.REACT_APP_BASE_URL}${endPoint}`;
 return  baseUrl;
};

