/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const HandleErrorResponse = (error) => {
  const errorCap =
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    error?.response?.data?.msg|| 'Error! Something Went Wrong';

  // console.log(errorCap, 'errorCap');

  return errorCap;
};

export const HandleSuccessMessage = (response) => {
  // console.log({response});
  
  const successCap =
    response?.data?.message ||
    response?.data?.data?.message ||
    response?.data?.msg ||
    response?.data?.data?.msg ||
    response?.message;
  return successCap;
};

export const HandleSuccessResponse = (success) => {
  const successCap =
    success === 200 ||
    success === 201 ||
    success === 202 ||
    success === 203 ||
    success === 204;
  return successCap;
};
export const HandleSuccessResponseMsg = (response) => {
  const msgCap = response?.data?.message;
  return msgCap;
};

export const GetStringAbbreviation = (str) => {
  const firstLetters = str
    .split(" ")
    .map((word) => word[0])
    .join("");

  return firstLetters;
};

export const formatCurrencyOld = (amount: string | number): string => {
  if ((!amount && amount !== 0) || Number.isNaN(amount as number)) {
    return "0";
  }
  // amount= ConvertToKobo(amount);

  const formattedNumber = amount.toLocaleString("en-US"); // This will result in '11,000'

  // amount = amount?.toString().replace(',', '');

  // return parseFloat(formattedNumber as any).toFixed(2).toLocaleString();
  return formattedNumber;
};

export const formatCurrency = (amountInNaira) =>{
  parseInt(amountInNaira)
  // Ensure that amountInNaira is a valid number
  if (isNaN(amountInNaira) || typeof amountInNaira !== 'number') {
    return "0";
  }

  // Convert Naira to Kobo
  const amountInKobo = amountInNaira * 100;

  // Format the amount for display
  const formattedAmount = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amountInKobo / 100);

  // Return the formatted amount
  return formattedAmount;
}
export const ConvertToKoboOld = (amountInNaira) => {
  // Ensure that amountInNaira is a valid number
  if (isNaN(amountInNaira) || typeof amountInNaira !== "number") {
    throw new Error("Invalid input. Please provide a valid number.");
  }

  // Convert Naira to Kobo
  const amountInKobo = amountInNaira * 100;

  // Return the amount in Kobo
  return amountInKobo;
};
